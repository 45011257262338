import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard', title: 'Dashboard', icon: 'fa fa-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/project', title: 'Company', icon: 'project', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/plant', title: 'Plants', icon: 'plant', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/location', title: 'Zones', icon: 'fa fa-map-marker', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/asset', title: 'Assets', icon: 'asset', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/component', title: 'Components', icon: 'component', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/sensor', title: 'IIOT Data', icon: 'sensor', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/manage-work', title: 'Manage Work', icon: 'fa fa-first-order', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            {
                path: '/cmms-dashboard', title: 'CMMS Dashboard', icon: 'fa fa-tachometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/work-order', title: 'Tasks', icon: 'fa fa-tasks', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/completed-task', title: 'Completed Tasks', icon: 'fa fa-check', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/submit-work-request', title: 'Submit Work Request', icon: 'fa fa-exclamation-triangle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/preventive-maintenance', title: 'PMs', icon: 'fa fa-wrench', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/part', title: 'Parts', icon: 'fa fa-building', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/requisition', title: 'Requisition', icon: 'fa fa-wpforms', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/vendor', title: 'Vendors', icon: 'fa fa-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            }
        ]
    },
    {
        path: '/data-comparison', title: 'Data Comparison', icon: 'fa fa-bar-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/integration', title: 'Integrations', icon: 'fa fa-gears', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/mapping', title: 'Mappings', icon: 'mapping', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/user', title: 'Users', icon: 'fa fa-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/role', title: 'Roles', icon: 'fa fa-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/shift', title: 'Shifts', icon: 'shift', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/report', title: 'Reports', icon: 'fa fa-file-text-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/setting', title: 'Settings', icon: 'fa fa-gear', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/documentation', title: 'Documentation', icon: 'fa fa-address-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
    
];
