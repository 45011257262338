<div class="modal-header">
    <h5 class="modal-title">Part</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Item Id *</label> 
                            <input type="text" class="form-control" placeholder="Enter Item"(keyup)="getPartByNumber()" formControlName="number" [(ngModel)]="part.number" />
                            <div *ngIf="error('number','required')" class="invalid">Number is required</div>
                            <div *ngIf="partExist" class="invalid">This part is already exist</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Description *</label>
                            <input type="text" class="form-control" placeholder="Enter Description" formControlName="name" [(ngModel)]="part.name" />
                            <div *ngIf="error('name','required')" class="invalid">Name is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Price *</label>
                            <input type="number" class="form-control" placeholder="Enter Price" formControlName="price" [(ngModel)]="part.price" />
                            <div *ngIf="error('price','required')" class="invalid">Price is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Quantity *</label>
                            <input type="Number" class="form-control" placeholder="Enter Quantity" formControlName="quantity" [(ngModel)]="part.quantity" />
                            <div *ngIf="error('quantity','required')" class="invalid">Quantity is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Categories</label>
                            <div class="input-group">
                                <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="part.category_id">
                                    <option value="">Select Category</option>
                                    <option *ngFor="let category of categories" [value]="category.category_id">
                                        {{category.name }}
                                    </option>
                                </select>
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="toggleAddCategoryBlock()"><i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                            <div class="input-group mt-2" [hidden]="!categoryBlock">
                                <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder="Enter category name" [(ngModel)]="categoryData.name">
                                <button type="button" class="btn btn-white" (click)="addCategory()">Add</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Alert Amount *</label>
                            <input type="Number" class="form-control" placeholder="Enter Amount" formControlName="alert_amount" [(ngModel)]="part.alert_amount" />
                            <div *ngIf="error('alert_amount','required')" class="invalid">Amount is required</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Vendors</label>
                            <ng-multiselect-dropdown [placeholder]="'Select vendors'" [settings]="setMultiselectSetting('vendor_id','name')" [data]="vendors" [ngModelOptions]="{standalone: true}" [(ngModel)]="part.vendors">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Upload Image</label> 
                            <div class="input-group">
                                <input (change)="onFileChanged($event)" type="file" name="file" class="form-control" #fileInput accept="image/*" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <img src="{{ image }}" class="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button *ngIf="!part.part_id" [disabled]="partExist" type="button" class="btn btn-white pull-right" (click)="validateForm({action: 'add', data: part})">Save</button>
    <button *ngIf="part.part_id" [disabled]="partExist"  type="button" class="btn btn-white pull-right" (click)="validateForm({action: 'update', data: part})">Update</button>
</div>