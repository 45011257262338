<div class="modal-header">
    <h5 class="modal-title text-capitalize">{{ obj.type }} Viewer</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="bg-viewer-header">
                        <div class="d-flex justify-content-between">
                            <h6 class="my-auto p-2">{{ obj.resource.name }}</h6>
                            <!-- <span class="viewer-badge-pill text-white bg-warning" title="Number of open PMs, WOs etc. for this {{ obj.type }}">14</span> -->
                        </div>
                        <input (change)="onFileChanged($event)" type="file" name="file" #openImageUploadDialog class="form-control hidden" #fileInput accept="image/*" />
                        <div class="bg-viewer-image">
                            <div class="row">
                                <div class="col-12 col-sm-7 d-flex">
                                    <img src="{{image}}" class="p-2" height="150" />
                                    <i class="fa fa-upload p-2 fa-1x text-white" (click)="openImageUploadDialog.click()"></i>
                                </div>
                                <div class="col-12 col-sm-5 text-white">
                                    <b><i class="fa fa-info-circle mx-2"></i>Information</b>
                                    <p class="ml-2">{{ obj.resource.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul class="nav nav-tabs nav-tabs-primary">
                        <li class="nav-item" *ngFor="let tab of viewerTabs">
                            <a class="nav-link" data-toggle="tab" (click)="makeActive(tab.item)" *ngIf="show(tab.item)" [ngClass]="{'active': tab.item == activeTab}" href="#{{ tab.item }}-{{obj.type}}">
                                <span>{{ tab.name }}</span>
                            </a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content tab-details">
                        <div id="info-{{obj.type}}" *ngIf="active('info')" class="container tab-pane" [ngClass]="{'active': active('info')}">
                            <app-viewer-info [obj]='obj'></app-viewer-info>
                        </div>
                        <div id="pms-{{obj.type}}" *ngIf="active('pms')" class="container tab-pane" [ngClass]="{'active': active('pms')}">
                            <app-viewer-pm [obj]='obj'></app-viewer-pm>
                        </div>
                        <div id="wos-{{obj.type}}" *ngIf="active('wos')" class="container tab-pane" [ngClass]="{'active': active('wos')}">
                            <app-viewer-work-order [obj]='obj'></app-viewer-work-order>
                        </div>
                        <div id="parts-{{obj.type}}" *ngIf="active('parts')" class="container tab-pane" [ngClass]="{'active': active('parts')}">
                            <app-viewer-part [obj]='obj'></app-viewer-part>
                        </div>
                        <div id="vendors-{{obj.type}}" *ngIf="active('vendors')" class="container tab-pane" [ngClass]="{'active': active('vendors')}">
                            <app-viewer-vendor [obj]='obj'></app-viewer-vendor>
                        </div>
                        <div id="childrens-{{obj.type}}" *ngIf="active('childrens')" class="container tab-pane" [ngClass]="{'active': active('childrens')}">
                            <app-viewer-children [obj]='obj'></app-viewer-children>
                        </div>
                        <div id="timeline-{{obj.type}}" *ngIf="active('timeline')" class="container tab-pane" [ngClass]="{'active': active('timeline')}">
                            <app-viewer-timeline [obj]='obj'></app-viewer-timeline>
                        </div>
                        <div id="data-comparison-{{obj.type}}" *ngIf="active('data-comparison')" class="container tab-pane" [ngClass]="{'active': active('data-comparison')}">
                            <app-data-comparison></app-data-comparison>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End Row-->