<!--Filter Row-->
<div class="row mb-2 mx-0">
	<div class="col-6 p-0 my-auto">
	  <h4 class="m-0 text-dark">Manage Sensors</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<select (change)="changeRowLimits($event)" class="limit"> 
				<option *ngFor="let limit of rowLimits" [value]="limit.value">{{limit.key}}</option>
			</select>
			<button *ngIf="component" [hidden]="hideOptions()" type="button" class="btn btn-white filter ml-2" (click)="openSensorFormModal()"><i class="fa fa-plus"></i></button>
			<app-pin [pinData]='pinData'></app-pin>
			<app-expand-screen></app-expand-screen>
			<button type="button" class="btn btn-white filter ml-2" (click)="ngOnInit()">Reload</button>
		</div>
	</div>
</div>
<div class="row mb-2" [hidden]="component">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row mt-3 mx-0">
					<div class="col-sm-6 col-md-4">
						<div class="form-group">
							<ng-multiselect-dropdown
							[placeholder]="'Select Plant'"
							[settings]="setMultiselectSetting('plant_id','name')" 
							[data]="plants"
							[(ngModel)]="filter.plants"
							(ngModelChange)="getPlantsLocations()"> 
							</ng-multiselect-dropdown> 
						</div>
					</div>
					<div class="col-sm-6 col-md-4">
						<div class="form-group">
							<ng-multiselect-dropdown
							[placeholder]="'Select Location'"
							[settings]="setMultiselectSetting('location_id','name')"
							[data]="locations"
							[(ngModel)]="filter.locations"
							(ngModelChange)="getLocationsAssets()">
							</ng-multiselect-dropdown> 
						</div>
					</div>
					<div class="col-sm-6 col-md-4">
						<div class="form-group">
							<ng-multiselect-dropdown
							[placeholder]="'Select Asset'"
							[settings]="setMultiselectSetting('asset_id','name')"
							[data]="assets"
							[(ngModel)]="filter.assets"
							(ngModelChange)="getAssetsComponents()">
							</ng-multiselect-dropdown> 
						</div>
					</div>
					<div class="col-sm-6 col-md-4">
						<div class="form-group">
							<ng-multiselect-dropdown
							[placeholder]="'Select Component'"
							[settings]="setMultiselectSetting('component_id','name')"
							[data]="components"
							[(ngModel)]="filter.components">
							</ng-multiselect-dropdown> 
						</div>
					</div>
					<div class="col-sm-6 col-md-4">
						<div class="form-group">
							<button type="button" class="btn btn-white mr-2 filter" title="Filter" (click)="getComponentSSensors()"><i class="fa fa-filter"></i></button>
							<button type="button" class="btn btn-white filter" title="Reset" (click)="resetComponentSensors()"><i class="fa fa-refresh"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--End Row-->
<!--List Group with Custom content-->
<div class="row">
	<div class="col-12">
		<div class="card">
			<div class="card-body">
				<div *ngFor="let key of keys;">
					<label class="p-3 mt-3 page-count w-100 text-white" style="background-color:#2D323E">{{key}} Sensors</label>
					<ngx-datatable [scrollbarH]="true" #mydatatable class="bootstrap" [headerHeight]="50" [limit]="limit" [columnMode]="'force'" [footerHeight]="50" [rowHeight]="'auto'" [rows]="rows[key]" (activate)="selectListener($event)">
						<ngx-datatable-column *ngFor="let item of showOnDashboard[key]" name="{{ item.label }}" prop="{{ item.key }}">
							<ng-template ngx-datatable-cell-template let-value="value">
								<span *ngIf="!image(value) && item.key != 'status'">{{value}}</span>
								<span *ngIf="image(value)"><img src="{{value}}" height="40" /></span>
								<button *ngIf="!image(value) && item.key == 'status' && active(value)" class="btn-social btn-social-circle btn-active waves-effect waves-light m-1"><i class="fa fa-check"></i></button>
								<button *ngIf="!image(value) && item.key == 'status' && deActive(value)" class="btn-social btn-social-circle btn-deactive waves-effect waves-light m-1"><i class="fa fa-close"></i></button>
							</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column name="Action">
							<ng-template ngx-datatable-cell-template let-value="value">
								<div class="col-12 d-flex fa-lg">
									<a href="javascript:;" (click)="openSensorFormModal(value.sensor_unique_id)"><i class="text-dark fa fa-cog mr-2"></i></a>
									<a href="javascript:;" (click)="openSensorReplaceFormModal(value.sensor_unique_id)"><i class=" text-dark fa fa-retweet mr-2"></i></a>
									<a href="javascript:;" [hidden]="hideOptions()" (click)="openConfirmationModal(value.sensor_unique_id)"><i class="text-dark fa fa-trash mr-2"></i></a>
									<a href="javascript:;" [hidden]="hideOptions()" (click)="openWidgetListModal({sensorUniqueId: value.sensor_unique_id, mappingId: value.mapping_id})"><i class=" text-dark fa fa-gears mr-2"></i></a>
									<a href="javascript:;" (click)="openSensorSetting(value)"><i class="text-dark fa fa-wrench mr-2"></i></a>
								</div>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
	<div class="col-lg-12" *ngIf="sensors.length == 0">
		<div class="card mb-1">
			<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
				<button class="btn btn-link text-dark shadow-none">
					<span>No data to display</span>
				</button>
			</div>
		</div>
	</div>
</div>
<!--End Row-->