import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SensorService {

    constructor(
        private _httpClient: HttpClient
    ) { }

    addSensor(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateSensor(sensor_unique_id, data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/sensor/' + sensor_unique_id, { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    replaceSensor(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/replace-sensor', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copySensor(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-sensor', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deleteSensor(sensor_unique_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/sensor/' + sensor_unique_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getSensorMapping(mappingId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-mapping/' + mappingId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorByComponentId(component_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-by-component/' + component_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorDetail(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-detail', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorSettingTabs(tabs): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-setting-tabs', tabs)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getTabs(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/tabs')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorsByGroup(group): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-by-group/' + group)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorWidgetData(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-widget-data', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getBulkSensorWidgetData(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/bulk-sensor-widget-data', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getHighResolutionSensorWidgetData(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/high-resolution-sensor-widget-data', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getCustomImonnitChartData(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/custom-imonnit-chart-data', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getMachineStatus(sensor_id, project_unique_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/machine-status/' + sensor_id + '/' + project_unique_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorActiveMaintenanceAlerts(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-active-maintenance-alerts/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorHealthChartData(sensor_id, project_unique_id, state): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-health-chart-data/' + sensor_id + '/' + project_unique_id + '/' + state)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getHealthWidgetData(sensor_id, project_unique_id, start_date): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/health-widget-data/' + sensor_id + '/' + project_unique_id + '/' + start_date)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateHealthWidgetData(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/health-widget-data', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getTemperaturePolicy(sensor_id, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/temperature-policy/' + sensor_id + '/' + type)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addTemperaturePolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/temperature-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateTemperaturePolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/temperature-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deleteTemperaturePolicy(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/temperature-policy/' + id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyTemperaturePolicy(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-temperature-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getDifferentialPolicy(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/differential-policy/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addDifferentialPolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/differential-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateDifferentialPolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/differential-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyDifferentialPolicy(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-differential-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getBearingNumber(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/bearing-number/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addBearingNumber(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/bearing-number', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateBearingNumber(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/bearing-number', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyBearingNumber(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-bearing-number', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getAmbientSensorList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/ambient')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAmbientTemperaturePolicy(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/ambient-temperature-policy/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addAmbientTemperaturePolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/ambient-temperature-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateAmbientTemperaturePolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/ambient-temperature-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyAmbientTemperaturePolicy(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-ambient-temperature-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getMaintenancePolicy(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/maintenance-policy/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addMaintenancePolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/maintenance-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateMaintenancePolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/maintenance-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deleteMaintenancePolicy(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/maintenance-policy/' + id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyMaintenancePolicy(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-maintenance-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getRpmPolicy(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/rpm-policy/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addRpmPolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/rpm-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateRpmPolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/rpm-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyRpmPolicy(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-rpm-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getUtilizationPolicy(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/utilization-policy/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addUtilizationPolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/utilization-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateUtilizationPolicy(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/utilization-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyUtilizationPolicy(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-utilization-policy', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getCoordinates(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/coordinates/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addCoordinates(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/coordinates', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateCoordinates(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/coordinates', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getClusterConfiguration(sensor_id, type): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/cluster-configuration/' + sensor_id + '/' + type)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addClusterConfiguration(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/cluster-configuration', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateClusterConfiguration(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/cluster-configuration', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateClusterSetting(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/cluster-setting', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyClusterConfiguration(data, copyType) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-cluster-configuration?copytype=' + copyType, { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getReminderType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/reminder-type')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addReminderType(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/reminder-type', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getSensorGroup(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-group')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addSensorGroup(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-group', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    editSensorGroup(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/sensor-group', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getSensorUsedFor(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-used-for')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addUsedFor(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-used-for', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getunit(usedFor): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-unit/' + usedFor)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addUnit(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-unit', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    importSensors(sensorsCsvFile): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/import-sensors/' + sensorsCsvFile)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-list')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getUsers(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/user')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAlerts(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/alerts', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAxisSettings(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/axis-settings/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addAxisSettings(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/axis-settings', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateAxisSettings(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/axis-settings', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    copyAxisSettings(data) {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-axis-settings', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    addFftJob(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/fft-job', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getFftJob(sensorId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/fft-job/' + sensorId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getJobStatus(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/job-status', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDataForFft(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/data-for-fft', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getFftHistory(selectedRecordId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/fft-history/' + selectedRecordId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getFftHistoryDates(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/fft-history', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getTreonFftTimeSlots(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/treon-fft-timeslots', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDataForTreonFft(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/data-for-treon-fft', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    onDemandTreonFft(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/on-demand-treon-fft', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
