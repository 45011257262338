<div class="row mb-2 mx-0">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Timeline</h4>
	</div>
	<div class="col-6 p-0 d-flex justify-content-end">
		<div class="form-group mr-2">
			<select class="form-control h-43" [(ngModel)]="filter.user_id" (change)="filterEvents()">
				<option value="">Select User</option>
				<option *ngFor="let user of users" value="{{user.user_id}}">{{user.name}}</option>
			</select>
		</div>
		<div class="form-group mr-2">
			<select class="form-control h-43" [(ngModel)]="filter.file_type" (change)="filterEvents()">
				<option value="">Select file type</option>
				<option value=".pdf">PDF</option>
				<option value=".csv">CSV</option>
				<option value=".xls">XLS</option>
				<option value=".txt">Text</option>
				<option value=".jpeg">JPEG</option>
				<option value=".jpg">JPG</option>
				<option value=".png">PNG</option>
			</select>
		</div>
		<div class="form-group mr-2">
			<input type="text" class="form-control h-43" placeholder="Comment, Work Order" (keyup)="filterEvents()" [(ngModel)]="filter.text" />
		</div>
		<div class="btn-group mr-2">
			<button type="button" class="btn btn-white" (click)="openAddCommentFormModal()">
				<i class="fa fa-plus"></i>
			</button>
		</div>
		<div class="btn-group">
			<button type="button" class="btn btn-white" (click)="creatReport()">
				<i class="fa fa-download"></i>
			</button>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<ngx-datatable #mydatatable class="bootstrap" [rows]="events" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [reorderable]="true">
							<ngx-datatable-column name="Date">
								<ng-template ngx-datatable-cell-template let-row="row">
									{{ helperService.formatDateWithTime(row.createdAt) }}
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="Entry">
								<ng-template ngx-datatable-cell-template let-row="row">
									<b>{{ row.title }}</b> {{ row.action }} {{ row.comment}} <span *ngIf="row.component_name && obj.type == 'asset'">( {{ row.component_name }} )</span><br>
									<img *ngIf="helperService.imageFile(row.attachment)" src="{{ row.attachment_path }}" height="100" />
									<img *ngIf="row.image" src="{{ row.image_path }}" height="100" />
									<a class="text-dark" *ngIf="!helperService.imageFile(row.attachment)" href="{{ row.attachment_path }}" target="_blank"> {{row.attachment}}</a>
									<video *ngIf="row.video" width="320" height="240" controls>
										<source src="{{ row.video_path }}" type="video/webm">
										<a class="text-dark" href="{{ row.video_path }}" target="_blank"> {{row.video}}</a>
									</video>
									<audio controls play pause volume *ngIf="row.audio">
										<source src="{{ row.audio_path }}" type="audio/mpeg">
										<a class="text-dark" href="{{ row.audio_path }}" target="_blank"> {{row.audio}}</a>
									</audio>
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="User">
								<ng-template ngx-datatable-cell-template let-row="row">
									<i class="fa fa-users" *ngIf="row.team_name"></i> {{ row.team_name }}
									<i class="fa fa-user" *ngIf="row.user_name"></i> {{ row.user_name }}
									<span *ngIf="row.users && row.users.length > 0">
										<p *ngFor="let user of row.users" class="m-0">
											<i class="fa fa-user"></i> {{ user.name }}
										</p>
									</span>

								</ng-template>
							</ngx-datatable-column>
						</ngx-datatable>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>